import React, { Component } from 'react';

import ButtonSwitcher from '../ButtonSwitcher';

import PricingTableItem from './components/PricingTableItem';

class PricingTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      planDuration: 'year',
    };
  }

  render() {
    const { planDuration } = this.state;

    return (
      <div>
        <div className="container-md flex flex-wrap flex-padded mt-20">
          <div className="sm:w-full flex-1">
            <PricingTableItem
              price={0}
              name="Free"
              features={[
                {
                  text: 'Plug and play contact form',
                  isPositive: true,
                },
                {
                  text: 'Data enriched e-mails',
                  isPositive: true,
                },
                {
                  text: 'Working attachments',
                  isPositive: true,
                },
                {
                  text: 'Send 50 emails per month',
                  isPositive: false,
                },
                {
                  text: 'No Custom from domains',
                  isPositive: false,
                },
                {
                  text: 'Standard response copy email',
                  isPositive: false,
                },
                {
                  text: 'ContactBubble branding',
                  isPositive: false,
                },
                {
                  text: 'No custom welcome message',
                  isPositive: false,
                },
              ]}
            />
          </div>
          <div className="sm:w-full flex-1">
            <PricingTableItem
              price={9}
              name="Pro"
              features={[
                {
                  text: 'Plug and play contact form',
                  isPositive: true,
                },
                {
                  text: 'Data enriched e-mails',
                  isPositive: true,
                },
                {
                  text: 'Working attachments',
                  isPositive: true,
                },
                {
                  text: 'Send 1.000 emails per month',
                  isPositive: true,
                },
                {
                  text: 'Add custom from domain',
                  isPositive: true,
                },
                {
                  text: 'Customize response copy email',
                  isPositive: true,
                },
                {
                  text: 'Hide branding',
                  isPositive: true,
                },
                {
                  text: 'Custom welcome message',
                  isPositive: true,
                },
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default PricingTable;
