import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import classNames from 'classnames';

const propTypes = {
  features: PropTypes.array,
  planDuration: PropTypes.string,
  price: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  text: PropTypes.string,
};

const defaultProps = {
  features: [],
  planDuration: 'year',
};

const PricingTableItem = (props) => {
  const {
    features, name, price, text,
  } = props;

  const titleClassName = classNames('inline-block w-auto callout leading-none', {
    'bg-primary-500 text-white': name === 'Pro',
    'bg-offwhite-darker': name !== 'Pro',
  });

  return (
    <div
      className={`relative h-full rounded-lg ${
        name === 'Pro' ? 'border-2 border-primary-500' : ''
      }`}
    >
      <div className="absolute top-0 left-0 text-center w-full rounded-lg">
        <h3 className={titleClassName} style={{ transform: 'translateY(-50%)' }}>
          {name}
        </h3>
      </div>
      <div className="bg-white callout p-8 pt-16 rounded-lg sm:mb-4 h-full">
        {price === 0 && (
          <p className="text-4xl text-center py-10">
            <strong>FREE</strong>
          </p>
        )}

        {price > 0 && (
          <Fragment>
            <p className="text-5xl md:text-6xl leading-none mb-6 font-normal text-center">
              <span>
                <span className="align-top font-normal mr-2" style={{ fontSize: '60%' }}>
                  $
                </span>
                {price}
                <span
                  className="align-bottom font-normal uppercase ml-2"
                  style={{ fontSize: '40%' }}
                >
                  USD
                </span>
              </span>
            </p>

            <p className="font-thin mb-10 text-center">Per month</p>
          </Fragment>
        )}

        <ul className="text-center">
          {features.map((feature) => {
            return (
              <li className={`py-1 ${!feature.isPositive ? 'text-gray-400 hidden md:block' : ''}`}>
                {feature.text}
              </li>
            );
          })}
        </ul>

        <p>{text}</p>
      </div>
    </div>
  );
};

PricingTableItem.propTypes = propTypes;
PricingTableItem.defaultProps = defaultProps;

export default PricingTableItem;
