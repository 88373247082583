import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from '../components/Layout';
import PricingTable from '../components/PricingTable';
import SignUpForm from '../components/SignUpForm';

const PricingPage = (props) => {
  return (
    <Layout {...props} hasWave>
      <Helmet>
        <title>Pricing | ContactBubble - The accessible contact form for your website or app</title>
        <meta
          name="description"
          content="Frictionless customer interactions for makers and small teams. ContactBubble is an embeddable contact form that runs on email."
        />

        <meta name="og:title" content="Pricing | ContactBubble" />
        <meta
          name="og:description"
          content="Frictionless customer interactions for makers and small teams. ContactBubble is an embeddable contact form that runs on email."
        />
        <meta name="og:url" content="https://www.contactbubble.com/pricing" />
      </Helmet>

      <div className="container-lg text-center pt-16 pb-8">
        <h1 className="text-center mb-0">Pricing</h1>
        <h2 className="h3">
          ContactBubble is so affordable,
          {' '}
          <br />
          it almost feels like it’s free.
        </h2>
      </div>

      <div className="pt-8 bg-white">
        <PricingTable />
      </div>

      <div className="pt-24 pb-16 bg-white">
        <div className="container-md">
          <div className="callout border-dashed border-grey-darkest border-2 p-8">
            <h3>Get your ContactBubble now!</h3>
            <SignUpForm />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PricingPage;
